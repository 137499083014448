<template>
  <div id="select_pc">
    <el-row :gutter="10">
      <el-col :span="8" class="s_col" type="flex">
        <div class="s_title">查询范围</div>
        <el-divider></el-divider>
        <dateTime ref="dateTime"></dateTime>
      </el-col>
      <el-col :span="3" class="s_col" type="flex">
        <div class="s_title">时间间隔</div>
        <el-divider></el-divider>
        <el-time-picker
          v-model="s_inter"
          align="center"
          placeholder="数据间隔时间"
        >
        </el-time-picker>
      </el-col>
      <el-col :span="4" class="s_col" type="flex">
        <el-radio v-model="dataList"  label="1" class="s_title">
        数据表源
        </el-radio>
        
        <el-divider></el-divider>
        <el-select v-model="cur_s_table" placeholder="数据源">
          <el-option
            v-for="tb in tables"
            :key="tb.name"
            :label="tb.tname"
            :value="tb.items"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" class="s_col" type="flex">
        <el-radio v-model="dataList"  label="2" class="s_title">
        自选数据
        </el-radio>
        <el-divider></el-divider>

        <el-button
          @click="itemSelectV = true"
          type="primary"
          icon="el-icon-search"
        >
          选择数据
        </el-button>
      </el-col>
      <el-col :span="3" class="s_col" type="flex">
        
        <div class="s_title">条件筛选({{isFilte_str}})</div>
        <el-divider></el-divider>

        <el-button
          @click="filteShow"
          type="primary"
          icon="el-icon-search"
        >
          设置条件
        </el-button>
      </el-col>
      <el-col :span="3" class="s_col" type="flex">
        
        <div class="s_title">数据统计</div>
        <el-divider></el-divider>

        <el-button
          @click="staShow"
          type="primary"
          icon="el-icon-search"
        >
          统计计算
        </el-button>
      </el-col>
      <el-col :span="4" class="s_col" type="flex">
        <br />
        <el-button
          @click="update_record"
          type="primary"
          icon="el-icon-search"
          round
        >
          查询
        </el-button>
      </el-col>
    </el-row>

    <el-dialog title="自选数据" :visible.sync="itemSelectV" width="80%">
      <ItemSelect
          ref="itemSelect"
          :tables="tables"
        >
      
          </ItemSelect>
        <el-button type="primary" @click="itemSelectV = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" title="筛选条件" :visible.sync="filteV1" width="50%">
      <filte_pc
      ref="filter1"
      :curList="curList"
      :filteV.sync = "filteV1"
      :fList.sync = "gList"

        >
        </filte_pc>
      </span>
    </el-dialog>

    <el-dialog :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" title="筛选条件" :visible.sync="filteV2" width="50%">
      <filte_pc
      ref="filter2"
      :curList="curList"
      :filteV.sync = "filteV2"
      :fList.sync = "iList"

        >
        </filte_pc>
      </span>
    </el-dialog>
    <el-dialog title="统计数据" :visible.sync="staV" width="80%">
      <sta_pc
          ref="staPc"
          :curList="curList"
        >
      
          </sta_pc>
        <el-button type="primary" @click="staV = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped>
#select_pc {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 10px;
}
.el-divider {
  margin: 6px 0px;
}
.s_col {
  height: 100%;
  align-items: center;
  min-width: 100px;
}
.el-date-editor--time {
  width: 100%;
}

.s_title{
  font-size:1em;
  line-height:20px;
  text-align:center;
  font-weight:bold;
}
#select_pc >>> .el-radio__label{
  font-size:1em;
  
}
</style>

<script>
//import { Row,Col,Divider} from  'element-ui'
//import {TimePicker } from  'element-ui'
//import {Select,Option} from  'element-ui'
//import {Button} from  'element-ui'

import dateTime from "../components/dateTime.vue";
import ItemSelect from "../components/ItemSelect.vue";
import filte_pc from "../components/filte_pc.vue";
import sta_pc from "../components/sta_pc.vue";

export default {
  components: {
    //[Row.name]:Row,
    //[Col.name]:Col,
    //[TimePicker.name]:TimePicker,
    //[Select.name]:Select,
    //[Option.name]:Option,
    //[Button.name]:Button,
    //[Divider.name]:Divider,
    dateTime,
    ItemSelect,
    filte_pc,
    sta_pc,
  },
  data() {
    return {
      s_inter: new Date(2021, 1, 1, 0, 5.0),
      cur_s_table: "正在获取中..",
      notifyshow: false,
      notifySpan: "",
      notifyColor: "#66CC66",
      itemSelectV: false,
      dataList:"1",
      filteV1 : false,
      filteV2 : false,
      myCol:[],
      gList:[{}],
      iList:[{}],
      curList:[],
      quering:"",
      
      staV:false,
    };
  },
  props: ["tables", "allLabel","webReqID"],
  methods: {
    update_record() {
      let filte
      if (this.dataList == "1"){
        filte = this.gList
      }
      else if (this.dataList == "2"){
        filte = this.iList
      }

      let obj = {
        bt: this.$refs.dateTime.dt[0],
        et: this.$refs.dateTime.dt[1],
        s_inter: this.s_inter,
        cur_s_table: this.get_cur_List(),
        filte,
        
      }

       
     
      if (obj.cur_s_table && obj.cur_s_table.length) {
      this.$parent.update_record(obj);
      }
      else {
        this.$message.error("您尚未选择数据列,请选择")
      }
      
    },
     
    get_cur_List(){
      if (this.dataList == "1"){
      return  this.cur_s_table
      }
      else if (this.dataList=="2"){
      /*return   this.$refs.itemSelect && this.$refs.itemSelect.colName_arr*/

        this.myCol =  JSON.parse(JSON.stringify(this.$refs.itemSelect && this.$refs.itemSelect.colName_arr))
        return this.myCol; 
      }
     },
    filteShow(){
        this.curList = this.get_cur_List()
      if (this.dataList == "1"){
        this.filteV1 = true
      }
      else if (this.dataList=="2"){
        this.filteV2 = true
      }

    },
    staShow(){
      this.curList = this.get_cur_List()
      this.staV = true

    }
  },
computed:{
           isFilte_str(){
             if (this.dataList == "1") {
              if  (
              this.gList[0].ifixLabel ||
              this.gList[0].logic ||
              this.gList[0].value 
              ){
                return "有"
              }
              else{
                return "无"
              }
             }
             if (this.dataList == "2") {
              if  (
              this.iList[0].ifixLabel ||
              this.iList[0].logic ||
              this.iList[0].value 
              ){
                return "有"
              }
              else{
                return "无"
              }
             }

           }

         },
  watch: {
    allLabel() {
      this.cur_s_table = this.tables[0].items;
    },
    cur_s_table(){
      this.gList=[{}]
    },
    webReqID(){
      if (this.webReqID){
        this.quering = this.$message({
          message: '正获取中，请勿重复点击查询',
          type: 'warning',
          duration:0  
            });
      }
      else {
        this.quering.close()
      }
    }
  },
};
</script>
