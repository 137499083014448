<template>
  <div id="filte_pc">
      <div class="item" v-for="(cfi, cfi_index) in fList" :key="cfi.ifixLabel">
        <el-select v-model="cfi.ifixLabel" placeholder="请选择">
          <el-option
            v-for="item in curList"
            :key="item.ifixLabel"
            :label="item.desc"
            :value="item.ifixLabel"
          >
          </el-option>
        </el-select>

        <el-select class="logic" v-model="cfi.logic" placeholder="请选择">
          <el-option
            v-for="item in logicList"
            :key="item.label"
            :label="item.label"
            :value="item.str"
          >
          </el-option>
        </el-select>

        <el-input
          class="vinput"
          v-model="cfi.value"
          placeholder="请输入数值"
        ></el-input>

        <el-button
          v-if="cfi_index == fList.length - 1"
          @click="addItem"
          type="primary"
        >
          新增
        </el-button>
        <el-select v-else class="op" v-model="cfi.op" placeholder="请选择">
          <el-option
            v-for="(item,key) in opList"
            :key="'op'+key"
            :label="item.label"
            :value="item.str"
          >
          </el-option>
        </el-select>
        <el-button
          v-if="cfi_index > 0"
          @click="remove(cfi_index)"
          type="primary"
        >
          删除
        </el-button>
      </div>
    <div class="qr">
      <el-button type="primary" @click="sure()">确认</el-button>
      <el-button type="primary" @click="clear()">清空</el-button>
    </div>
  </div>
</template>
<style scoped>
#filte_pc >>> .el-select {
  margin: 2px 3px;
}


.item {
  margin: 5px 0;
}

.qr {
  margin-top: 2em;
}
.qr * {
  margin: 0 2em;
}
#filte_pc >>> .el-container {
  display: block;
}

.vinput {
  width: 120px;
}
.logic {
  width: 100px;
}

.op {
  width: 100px;
}
</style>

<script>
//"vetur.ignoreProjectWarning":true
export default {
  /*props: ["filteData","filteV"],*/
  props: ["curList", "fList", "filteV"],
  data() {
    return {
      logicList: [
        {
          label: "大于",
          str: ">",
        },
        {
          label: "小于",
          str: "<",
        },
        {
          label: "等于",
          str: "=",
        },
        {
          label: "大于等于",
          str: ">=",
        },
        {
          label: "小于等于",
          str: "<=",
        },
      ],
      opList: [
        {
          label: "并且",
          str: "and",
        },
        {
          label: "或者",
          str: "or",
        },
      ],
      ifixLabel: "",
      logic: "",
      value: "",
    };
  },
  methods: {
    addItem() {
      this.fList.push({});
    },
    sure() {
      let ok = true 
      for (let index=0 ; index<this.fList.length; index++ ){
        let a = 0
        let b = 0
        let c = 0
        let d = 0

        let f = this.fList[index]

        if (f.ifixLabel)
            a = 1
        if (f.value || f.value === "0") 
            b = 1
        if (f.logic)
            c = 1
          
        let op_l = true 
        if (index == this.fList.length -1 )
        {
           op_l = true 
        }else{
           op_l =  false
        }

        if (!op_l && f.op )
          d = 1
         
            let m = a+b+c
            let n = a+b+c+d

            if (this.fList.length == 1){
                  if (!(m == 3 || m==0)){
                      ok = false
                      break
                  }

            }        
            else{
              if (op_l){
                if (m !==3){
                      ok = false
                      break
                }

              }else{
                if (n !==4){
                      ok = false
                      break
                }

              }

            }

      }

      if (ok){
        if (this.fList[this.fList.length -1].op){
            delete this.fList[this.fList.length-1].op
        }
        this.$emit("update:filteV", false);
      }
      else {
        this.$message.error('您有选项未填写')
      }
      return 
    },
    clear(){
        this.$emit("update:fList", [{}]);
    },
    remove(index){
      this.fList.splice(index,1)
    }
  },
};
</script>
