
<template>
<div id="dateTime">
    <el-date-picker
      v-model="dt"
      type="datetimerange"
      :picker-options="pickerOptions"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      align="right"
      :default-time="['00:00:00','23:59:59']"
    >
    </el-date-picker> 

</div>
</template>


<style scoped>
 .el-date-editor--datetimerange{
    width:100%

  }

</style>

<script>
//import {DatePicker,TimePicker } from  'element-ui'

export default{
        name: 'dateTime',
        components: {
          //[DatePicker.name]:DatePicker,
          //[TimePicker.name]:TimePicker,
        },
        data(){
          return{
              //default_time:["11:00:00",""],
              
							dt:[
                  new Date(new Date().setHours(0, 0, 0)),
                  new Date(new Date().setHours(23, 59, 59))
								/*new Date(((new Date()).valueOf())-(30*24*60*60*1000)).setHours(0,0,0),*/
								/*new Date(),*/
								],
              pickerOptions: {
						 disabledDate(time) {
            return time.getTime() > (new Date()).setHours(23,59,59,100);
          },
          shortcuts: [
            {text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.setHours(0,0,0,0));
              picker.$emit('pick', [start, end]);
            }},
            {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          }],
          }

        }
      },
}
    
</script>
