<template>
  <div id="StaPc">
    <el-row :gutter="5" style="width: 100%">
      <el-col :span="6" class="s_col" type="flex">
        <div>分组间的最小时间间隔(秒)</div>
        <br />
        <el-input
          class="vinput"
          v-model="inter"
          placeholder="请输入间隔(分钟)"
        ></el-input>
      </el-col>
      <el-col :span="8" class="s_col" type="flex">
        <div>数据列</div>
        <br />
        <el-select placeholder="请选择" v-model="desc">
          <el-option
            v-for="item in curList"
            :key="item.ifixLabel"
            :label="item.desc"
            :value="item.desc"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="s_col" type="flex">
        <div>统计类型</div>
        <br />
        <el-select placeholder="请选择" v-model="staType">
          <el-option
            v-for="(item, index) in staTypeList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" class="s_col" type="flex">
        <div>操作</div>
        <br />
        <el-button @click="cx" type="primary"> 统计 </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="tble.list" style="width: auto">
        <el-table-column
          v-for="(h, i) in tble.head"
          :key="i"
          :formatter="staTypeList[staType].formatter"
          :prop="i + ''"
          :label="h"
        >
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<style scoped>
::v-deep .el-input__inner {
text-align:right;
}


</style>


<script>
import dateFormat from "@/commonJS/dateFormat.js";
export default {
  props: ["curList"],
  data() {
    return {
      inter: 10,
      desc: "",
      staType: "",
      tble: {
        head: [],
        list: [],
      },

      staTypeList: [
        {
          label: "持续时间统计",
          value: 0,
          fn: this.timeLengthSta,
          formatter: this.dataTofixed,
        },
      ],
    };
  },
  methods: {
    timeLengthSta() {
      let rc_arr = this.$parent.$parent.$parent.record.data;
      let staList = [];
      let tg_arr = [];
      /*tg_arr.push(dateFormat.strToFormat(rc_arr[0].ym + rc_arr[0].rows[0][0]));*/
      let it = this.inter;
      /*let it = this.inter * 60;*/
      for (let rc of rc_arr) {
        let rows = rc.rows;
        if (!rows.length){
          continue
        }
        let ym = rc.ym;
      tg_arr.push(dateFormat.strToFormat(ym + rows[0][0]));
        for (let i = 1; i < rows.length; i++) {
          let ymt_p = dateFormat.strToFormat(ym + rows[i - 1][0]);
          let ymt_n = dateFormat.strToFormat(ym + rows[i][0]);
          let ymt_p_d = new Date(ymt_p);
          let ymt_n_d = new Date(ymt_n);
          if (parseInt(ymt_n_d - ymt_p_d) / 1000 > it) {
            tg_arr.push(ymt_p);
            tg_arr.push(ymt_n);
          }
        }
          tg_arr.push(dateFormat.strToFormat(ym + rows[rows.length - 1][0]));
      }
      /*tg_arr.push(*/
      /*dateFormat.strToFormat(*/
      /*rc_arr[rc_arr.length - 1].ym +*/
      /*rc_arr[rc_arr.length - 1].rows[*/
      /*rc_arr[rc_arr.length - 1].rows.length - 1*/
      /*][0]*/
      /*)*/
      /*);*/
      let sta = [];
      let sta_p = "";
      for (let i = 0; i < tg_arr.length; i++) {
        if (i % 2 === 0) {
          sta_p = tg_arr[i];
        } else {
          let sta_n = tg_arr[i];
          let xc = (new Date(sta_n) - new Date(sta_p)) / 1000 / 3600;
          if (xc == 0){
            xc = 5/3600;
          }
          let xc_str = [sta_p + " 至 " + sta_n, xc];
          sta.push(xc_str);
        }
      }

      let total = 0;
      for (let s of sta) {
        total += s[1];
      }
      /*sta.push(["总计", total]);*/
      sta.unshift(["总计", total]);

      this.showStaRes(["时间段", "持续时间(小时)"], sta);
    },

    cx() {
      this.staTypeList[this.staType].fn();
    },
    showStaRes(head, list) {
      /*Vue.set(this.tble.head, head);*/
      /*Vue.set(this.tble.list, list);*/
      /*this.$forceUpdate()*/
      this.tble.head = head;
      this.tble.list = list;
    },
    dataTofixed(row, col, value) {
      if (col.property == 0) {
        return value;
      } else {
        return (value * 1).toFixed(5);
      }
    },
  },
};
</script>
