<template>
  <div id="ItemSelect">
    <el-container>
      <el-aside width="auto">
        <div class="listLabel">数据组</div>
        <br />
        <el-tabs ref="groupList" tab-position="left" @tab-click="groupClick">
          <el-tab-pane
            v-for="(group, index) in tables"
            :key="index"
            :label="group.tname"
            :name="index + ''"
          ></el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-aside width="auto">
        <div class="listLabel">数据项</div>
        <br />
        <el-tabs ref="itemList" tab-position="left" @tab-click="itemClick">
          <el-tab-pane
            v-for="(item, index) in tables[groupIndex].items"
            :key="index"
            :label="item.desc"
            :name="index + ''"
          ></el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-aside width="150px">
        <div class="listLabel">操作</div>
        <br>
        <el-button @click="addItem" type="primary">
          添加>>
        </el-button>
        <br />
        <br />
        <br />
        <br />
        <el-button @click="clearItem" type="primary">
          清空<<
        </el-button>
      </el-aside >
      <el-aside width="auto">
        <div class="listLabel">自选项</div>
        <br />
        <el-tabs
          closable
          type="card"
          tab-position="left"
          @tab-remove="removeTab"
        >
          <el-tab-pane
            v-for="(group, index) in colName_arr"
            :key="index"
            :label="group.desc"
            :name="index + ''"
          ></el-tab-pane>
        </el-tabs>
      </el-aside>
    </el-container>
  </div>
</template>

<style>
#ItemSelect .el-transfer {
  text-align: left;
}

#ItemSelect .listLabel{
  font-weight:bold;
  font-size:1.3em;
}
</style>

<script>
export default {
  props: ["tables"],
  name: "ItemSelect",
  data() {
    return {
      groupIndex: 0,
      colName_arr: [],
    };
  },
  methods: {
    groupClick(tab) {
      this.groupIndex = tab.index;
    },
    itemClick(tab) {},
    addItem() {
      let g = this.$refs.groupList.currentName * 1;
      let i = this.$refs.itemList.currentName * 1;
      this.colName_arr.push({
        ...this.tables[g].items[i]
      });
    },
    removeTab(targetName) {
      this.colName_arr.splice(targetName, 1);
    },
    clearItem() {
      this.colName_arr = [];
    },
  },
};
</script>
